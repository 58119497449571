<template>
  <b-card title="Last errors of scripts" v-if="last_errors">
       <b-table :items="last_errors" tbody-tr-class="rowClass" thead-class="smallHeader">
         <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'timestamp' ? '22%' : ( field.key === 'script_name' ? '15%' : null) }"
        />
      </template>
       </b-table>
       <span v-if="last_errors.length==0">No errors registered</span>
  </b-card>
</template>

<script>
export default {
  data(){return{
    last_errors: null,
  }},
  methods: {
    load_data()
    {
      this.backend.get("/status/last_errors").then(resp => 
      {
        if (resp.data)
         {
           this.last_errors = resp.data;
         }
      });
    }
  },
  created(){
    this.load_data()
  }
}
</script>