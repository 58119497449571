<template>
  <b-card title="Last runs of scripts" v-if="last_runs">
       <b-table :items="last_runs" tbody-tr-class="rowClass" thead-class="smallHeader">
         <template v-slot:cell(status)="data">
         <div class="ticons" v-if="data.item.status=='error'"><v-icon name="alert-octagon" class="red" /></div>
         <div class="ticons" v-if="data.item.status=='done'"><v-icon name="check" /></div>
       </template>
       </b-table>
       <span v-if="last_runs.length==0">No runs registered</span>
  </b-card>
</template>

<script>
export default {
  data(){return{
    last_runs: null,
  }},
  methods: {
    load_data()
    {
      this.backend.get("/status/last_runs").then(resp => 
      {
        if (resp.data)
         {
           this.last_runs = resp.data;
         }
      });
    }
  },
  created(){
    this.load_data()
  }
}
</script>