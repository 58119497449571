<template>
  <div class="row justify-content-center">
  <div class="col-4">
    <last-runs />
  </div>
  <div class="col-8">
    <last-errors />
  </div>
  </div>
</template>

<script>
import LastRuns from './LastRuns'
import LastErrors from './LastErrors'

export default {
  components: {LastErrors,LastRuns}
}
</script>